<template><div><h1 id="updating-mrchromebox-custom-firmware" tabindex="-1"><a class="header-anchor" href="#updating-mrchromebox-custom-firmware"><span>Updating MrChromebox Custom Firmware</span></a></h1>
<div class="custom-container warning"><p class="custom-container-title">IMPORTANT</p>
<p>The Firmware Utility Script can only be run from ChromeOS or Linux.
If you are using Windows, boot a live Linux ISO (such as Ubuntu or Fedora) from USB and run the script from there.</p>
</div>
<div class="custom-container tip"><p class="custom-container-title">NOTE</p>
<p>The curl application is not installed by default on some Ubuntu / Ubuntu-based distros.
To install it, run: <code v-pre>sudo apt update &amp;&amp; sudo apt install -y curl</code></p>
</div>
<ol>
<li>Run the <RouteLink to="/docs/fwscript.html">Firmware Utility Script</RouteLink>:
<ul>
<li>From a terminal, run: <code v-pre>cd; curl -LO mrchromebox.tech/firmware-util.sh &amp;&amp; sudo bash firmware-util.sh</code> and press <code v-pre>[ENTER]</code>.</li>
</ul>
</li>
<li>Choose option 1: <code v-pre>Install/Update UEFI (Full ROM) Firmware</code> and press <code v-pre>[ENTER]</code>.</li>
<li>Follow the prompts.</li>
<li>Assuming no errors occur, reboot your device.</li>
</ol>
</div></template>


